export const random = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min

export const decodeEntities = (encodedString: string) => {
  var textArea = document.createElement('textarea')
  textArea.innerHTML = encodedString
  return textArea.value
}

export const isNullOrUndefined = <T>(obj: T | null | undefined): obj is T =>
  obj === null || obj === undefined
