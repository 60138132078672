import { useCallback, useEffect, useState } from 'react'
import { Button, Header, Icon } from 'semantic-ui-react'
import { isNullOrUndefined } from '../utils'

import { Comment } from '../data/useRandomComment'

type Guess = 'human' | 'bot'
type Result = 'correct' | 'incorrect'

type Props = {
  comment: Comment
  nextComment: () => void
}

type AnswerProps = {
  comment: Comment
  result: Result
}

const Answer = ({ comment, result }: AnswerProps) => (
  <>
    <Header textAlign="center">
      {result === 'correct' ? '✔ Правильно!' : '❌ Неверно!'}
    </Header>
    {!isNullOrUndefined(comment.id) ? (
      <p>
        Комментарий был написан под
        <a
          href={`https://rus.delfi.lv/?id=${comment.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          этой{' '}
        </a>
        статьей.
      </p>
    ) : (
      <p>Комментарий был сгенерирован компьютером.</p>
    )}
  </>
)

export const Vote = ({ comment, nextComment }: Props) => {
  const [result, setResult] = useState<Result | null>(null)

  useEffect(() => {
    setResult(null)
  }, [comment])

  const vote = useCallback(
    (guess: Guess) => () => {
      const correctAnswer: Guess = !isNullOrUndefined(comment.id)
        ? 'human'
        : 'bot'

      const result = guess === correctAnswer ? 'correct' : 'incorrect'

      ;(window as any).plausible(`${result}-guess`)

      setResult(result)
    },
    [comment]
  )

  if (result !== null)
    return (
      <>
        <Answer comment={comment} result={result} />
        <Button icon labelPosition="right" basic fluid onClick={nextComment}>
          Следующий комментарий
          <Icon name="arrow right" />
        </Button>
      </>
    )

  return (
    <>
      <Header textAlign="center">Кто написал этот комментарий?</Header>
      <Button.Group fluid>
        <Button color="yellow" onClick={vote('human')}>
          Человек
        </Button>
        <Button.Or text="или" className="btn-or" />
        <Button color="blue" onClick={vote('bot')}>
          Нейросеть
        </Button>
      </Button.Group>
    </>
  )
}
