import { random } from '../utils'
import './delfi-comment.css'

export const DelfiComment: React.FC = ({ children }) => (
  <div>
    <div className="comment">
      <div className="comment-avatar">
        <span className="comment-anonymous"></span>
      </div>
      <div className="comment-body">
        <div className="comment-subject">
          <div className="inline-block">Аноним</div>
        </div>
        <div className="created-time">{new Date().toLocaleString('ru')}</div>
        <div className="comment-content">
          <div>{children}</div>
        </div>
        <div className="comment-actions">
          <ul>
            <li>
              <span>
                <div className="reaction-btn">Реагировать</div>
              </span>
            </li>
          </ul>
          <ul className="reactions">
            <li className="flex-container">
              <div className="reaction-icon">
                <div>
                  <div title="Angry" className="reaction">
                    <img src="https://g.delphi.lv/misc/comment-fe/img/reactions/reaction-angry.svg" />
                  </div>
                </div>
              </div>
              <div className="reaction-cnt">
                <span>{random(0, 99)}</span>
              </div>
            </li>
            <li className="reaction-total-cnt">
              <span>{random(0, 99)}</span>
            </li>
          </ul>
          <div title="Ответить" className="as-link reply-comment">
            Ответить
          </div>
        </div>
      </div>
    </div>
  </div>
)
