import { DelfiComment } from './delfi-comment'
import './app.css'
import { getRandomComment } from '../data/useRandomComment'
import { useCallback, useState } from 'react'
import { decodeEntities } from '../utils'
import { Vote } from './vote'

const newLineText = (text: string) =>
  text.split('\\r\\n').map((str, i) => <p key={i}>{decodeEntities(str)}</p>)

export const App = () => {
  const [comment, setComment] = useState(getRandomComment())

  const nextComment = useCallback(() => {
    ; (window as any).plausible(`play-again`)
    setComment(getRandomComment())
  }, [setComment])

  return (
    <div className="app">
      <div className="app-comment">
        <div className="app-comment-box">
          <DelfiComment>{newLineText(comment.text)}</DelfiComment>
        </div>
      </div>
      <div className="app-vote">
        <Vote comment={comment} nextComment={nextComment} />
      </div>
      <div className="footer">
        <div>
          Еще больше нейрокомментариев в{' '}
          <a href="https://t.me/DelfiCommentBot" target="_blank" rel="noopener noreferrer">
            telegram боте
          </a>.
        </div>
        Автор:{' '}
        <a href="https://dexie.me" target="_blank" rel="noopener noreferrer">
          @minajevs
        </a>
        , исходный код на{' '}
        <a
          href="https://github.com/minajevs/delfi-or-computer"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        , 2021
      </div>
    </div>
  )
}
