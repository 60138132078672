import { random } from '../utils'
import realComments from './real-comments.json'
import fakeComments from './fake-comments.json'

export type Comment = { id: string | null; text: string }

const mappedFakeComments = fakeComments.map((x) => ({ ...x, id: null }))

export const getRandomComment = (): Comment => {
  const comments = Math.random() < 0.5 ? realComments : mappedFakeComments

  const comment = comments[random(0, comments.length)]
  return comment
}
